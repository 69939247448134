@tailwind base;
@tailwind components;
@tailwind utilities;

body,html,#root {
 margin: 0px;
 padding: 0px;
 height: 100%;
}

/* #root {
  height: 100%;
} */

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}